<template>
  <div>
    <h4>
      <img src="@/assets/images/inner3_bg.png">
      PREGUNTAS FRECUENTES
    </h4>
    <div class="faq_list">
      <div class="container">
        <!-- <h3 class="title">Frequently Asked Questions</h3> -->
        <!-- <img src="@/assets/images/loanImg/icon_faq.png" alt=""> -->
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
            <div v-html="item.dec" />
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1. ¿Cómo puedo obtener a los préstamos de PastaBasta?',
          dec: 'Se puede acceder a nuestra plantaforma de préstamos a través de Google Play. Abra Google Play y busque la aplicación “PastaBasta”, luego descargue y comience a registrarse con su número de celular.'
        },
        // {
        //   name: '2',
        //   id: 'question-2',
        //   title: '2. ¿CUAL ES LA FORMA DE REEMBOLSAR EL PRESTAMO?',
        //   dec: '1. Pago con Baloto<br>2. Corresponsales de STP<br>3. Doteaplus - payValida<br>4. PUNTO ROJO - Bancupo<br>5. Pague en línea a través de PSE, seleccione Pagar y realice pagos virtuales.'
        // },
        {
          name: '2',
          id: 'question-2',
          title: '2. ¿Puedo eliminar mi cuenta de la aplicación?',
          dec: 'Según nuestros Términos de servicio y nuestra Política de privacidad , recopilamos información sobre usted para determinar si califica para un préstamo. No vendemos ni distribuimos esta información para ningún otro propósito. Si desea terminar su relación con PastaBasta, puede desinstalar la aplicación.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3. ¿Qué se requiere para recibir un préstamo de PastaBasta?',
          dec: 'Los requisitos son simples: nos ofrece su número de celular y responder algunas preguntas. También solicitaremos acceso a los datos en su celular para determinar su elegibilidad.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4. ¿Cómo toma PastaBasta una decisión de préstamo?',
          dec: 'PastaBasta utiliza datos de su celular, para tomar decisiones de préstamo. Se combina esto con su historia de pago de PastaBasta para construir una puntuación de crédito personalizada, que determina el monto de préstamos de usted.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5. ¿Por qué me rechazaron para un préstamo?',
          dec: 'Su puntaje crediticio integral falló, mantenga un buen historial crediticio e intente nuevamente después de un período de tiempo.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6. ¿Cómo puedo aumentar mis posibilidades de ser aceptado?',
          dec: 'PastaBasta utiliza datos de su celular y otras fuentes para tomar una decisión de préstamo. Para aumentar sus posibilidades de aprobación, por favor asegúrese de no borrar la aplicación de su celular, mantenga buena historia de crédito con otros institutos de préstamo, y escriba correctamente los datos de su cuenta.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7. ¿Por qué me pide información de mi familia y contacto de emergencia?',
          dec: 'Sus familiares y contactos de emergencia son la persona que puede demostrar su confiabilidad, credibilidad y honestidad. Por favor complete la información del contacto.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8. ¿Puedo pagar antes? ',
          dec: 'Se acepta el reembolso anticipado. Se recomienda que pague el préstamo a tiempo para aumentar el monto del préstamo en el futuro.'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9. ¿Por qué necesitas permiso para mi celular?',
          dec: 'PastaBasta utiliza datos de su celular, incluidos los detalles de su celular y mensajes de transacciones financieras para tomar decisiones de préstamo. Estos datos son una parte crucial de nuestro proceso de toma de decisiones y nos permiten proporcionar servicios financieros eficientes y sin problemas.'
        },
        {
          name: '10',
          id: 'question-10',
          title: '10. ¿Cómo otorgo permisos a PastaBasta en mi celular? ',
          dec: 'En su celular Android, vaya a Configuración> Aplicaciones> PastaBasta> Permisos y asegúrese de haber habilitado a PastaBasta para acceder a todo lo solicitado.'
        }
        // {
        //   name: '11',
        //   id: 'question-11',
        //   title: '11. ¿Cómo puedo aumentar mi crédito?',
        //   dec: 'La línea de crédito es generada automáticamente por el sistema, manteniendo un excelente y oportuno reembolso, lo que le permite obtener más dinero la próxima vez que pida prestado.'
        // }
      ]
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    }
  }

}
</script>

<style lang="scss" scoped>
h4{
  width: 100%;
  padding:0px calc((100% - 1200px) / 2) 0;
  // background: linear-gradient(270deg, #C54347 0%, #7149BC 100%);
  background: #3984E5;
  color: white;
  line-height: 120px;
  font-size: 25px;
  display: flex;
  align-items: center;
  img{
    width: 250px;
  }
}
.faq_list{
  min-width: 1200px;
  padding: 50px calc((100% - 1100px) / 2) 50px;
  margin: 0 auto;
  img{
    display: block;
    margin: 0 auto;
    width: 350px;
    height: 300px;
  }
  .title{
    font-size: 30px;
    color: #333333;
    margin-bottom: 40px;
  }
}
:deep(.el-collapse-item__header) {
  background-color:#EEEEEE;
  padding-left: 20px;
}
:deep(.el-collapse-item__content) {
  padding: 20px 480px 50px 20px;
}
@media only screen and (max-width: 665px){
  h4{
    padding-left: 20px;
    font-size: 12px;
    line-height: 50px;
    img{
      width: 100px;
      margin: 5px 30px 0 0;
    }
  }
  .faq_list{
    min-width: calc(100vw);
    padding: 0 !important;
    .container{
      img{
        width: 250px;
        height: 200px;
        margin: 10px auto;
      }
    }
  }
  :deep(.el-collapse-item__header) {
    padding-left: 20px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: 50px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 20px 20px !important;
    font-size: 12px !important;
  }
}
</style>
